import React from "react";
import { Link } from "@quintype/components";
import useUrl from "../../../../hook/useUrl";
import { NewspaperLogo } from "../newspaper-logo";
import { FooterAppLogo } from "../footer-app-logo";
import { generateUrl } from "../../../utils/nav-urls";
import FooterSocialMediaIcons from "../footer-social-media-icon";
import "./common-footer.m.css";

const StaticFooterLinks = ({ staticFooterLinks }) => {
  return (
    <ul styleName="static-link">
      {staticFooterLinks.length >= 1 &&
        staticFooterLinks.map(item => {
          return item.isExternalLink ? (
            <li styleName="static-link-list" key={item.title}>
              <a href={item.completeUrl} styleName="static-link-item" target="_blank" rel="noreferrer">
                {item.title}
              </a>
            </li>
          ) : (
            <li styleName="static-link-list" key={item.title} target="_blank">
              <Link href={item.completeUrl || "/"} styleName="static-link-item">
                {item.title}
              </Link>
            </li>
          );
        })}
        {/*Evästeasetusplaceholder, toiminnallisuus gtm:stä*/}
        <li styleName="static-link-list" key="evästeasetukset">
          <a styleName="static-link-item evasteasetukset" className="evasteasetukset">
            Evästeasetukset
          </a>
        </li>
    </ul>
  );
};

const ContactInfo = ({currentUrlSlug}) => {
  let katuosoite = "";
  let puhelin="";
    switch (currentUrlSlug) {
      case "vaasalehti":
        katuosoite = "Hietasaarenkatu 19, Vaasa";
        puhelin="06 2477 965"
        break;
      case "epari":
        katuosoite = "Koulukatu 10, Seinäjoki";
        puhelin="06 2477 865"
        break;
      default:
        katuosoite = "";
    }

  return (
    <React.Fragment>
      {
        <div styleName="footer-info">
          <div>
            <p styleName="footer-contact-heading">Katuosoite</p>
            {katuosoite}
          </div>
          <div>
            <p styleName="footer-contact-heading">Puhelin</p>
            {puhelin}
          </div>
          <div>
            <p styleName="footer-contact-heading"></p>
            <a href="https://asiakaspalvelu.i-mediat.fi/vaasa/#ota-yhteytta" target="_blank" styleName={`opening-hours-${currentUrlSlug}`}>Katso aukioloajat</a>
          </div>
          <div>
            <p styleName="footer-contact-heading">{currentUrlSlug==="vaasalehti"&&"Vaasa on aina in"}</p>
            <div styleName="logo">
              <FooterAppLogo />
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

const FooterMenuLinks = ({ footerMenuLinks,currentUrlSlug }) => {
  return (
    <React.Fragment>
      {footerMenuLinks.length >= 1 && (
        <div styleName="menu-link">
          {footerMenuLinks.map(item => {
            return (
              <ul key={item.title} styleName="footer-link-column">
                <li key={item.title} styleName="footer-item">
                  {item.title}
                  <br />
                </li>
                {item.children.map(child => (
                    <li key={child.title} styleName="footer-child-item">
                      {(child.isExternalLink && !((child.completeUrl.includes("vaasalehti.fi") ||
                            (child.completeUrl.includes("localhost")) ||
                            (child.completeUrl.includes("qtstage")))) || (child.completeUrl.includes("lounaspaikka")) === true) ? (
                        <a styleName={`link-${currentUrlSlug}`} rel="noopener noreferrer" href={child.completeUrl} target="_blank">
                          {child.title}
                        </a>
                      ) : (
                        <a styleName={`link-${currentUrlSlug}`} rel="noopener noreferrer" href={generateUrl(child,currentUrlSlug)}>
                          {child.title}
                        </a>
                      )}
                    </li>

                ))}
              </ul>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

const CommonFooter = ({staticFooterLinks,footerMenuLinks,currentUrlSlug}) => {
  const [toHideNative,] = useUrl();
  return (
    <div styleName={!toHideNative ? "wrapper" : "hide"}>
      <div styleName="inner-wrapper">
        <div styleName="newspaper-logo">
          <NewspaperLogo />
        </div>
        <div styleName="footer-line"></div>
        <div styleName="footer-grid">
          <div styleName="top-row">
            <div styleName="left-column">
              <ContactInfo currentUrlSlug={currentUrlSlug}/>
            </div>
            <div styleName="center-column">
              <div styleName="up-button-mobile-div">
                <button
                  onClick={() =>
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth"
                    })
                  }
                  styleName={`up-button-mobile-${currentUrlSlug}`}
                >
                  <div styleName="up-button-text">
                    TAKAISIN YLÖS <i className="ri-arrow-up-line"></i>
                  </div>
                </button>
              </div>

              <FooterMenuLinks footerMenuLinks={footerMenuLinks} currentUrlSlug={currentUrlSlug} />
            </div>
            <div styleName="right-column">

                <button
                  onClick={() =>
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth"
                    })
                  }
                  styleName={`up-button-${currentUrlSlug}`}
                >
                  <div styleName="up-button-text">
                    TAKAISIN YLÖS <i className="ri-arrow-up-line"></i>
                  </div>
                </button>

              <div styleName="footer-social-media">
                <FooterSocialMediaIcons currentUrlSlug={currentUrlSlug}/>
              </div>
              {/*<div styleName="app-badges">
                <GooglePlayBadge />
                <AppStoreBadge />
                </div>*/}
            </div>
          </div>
        </div>
      </div>
      <div styleName="footer-bottom-links">
        <StaticFooterLinks staticFooterLinks={staticFooterLinks} />
      </div>
    </div>
  );
};

export { CommonFooter };
